import { Controller } from "@hotwired/stimulus"
import Highcharts from 'highcharts';

export default class extends Controller {

  static values = {
    inputs: Object
  }

  connect() {
    var annual_cash_flows_years = this.inputsValue.annual_cash_flows_years.map(str => Number(str));
    var annual_cash_flows_operating_cash_flows = this.inputsValue.annual_cash_flows_operating_cash_flows.map(str => Number(str));
    var annual_cash_flows_investing_cash_flows = this.inputsValue.annual_cash_flows_investing_cash_flows.map(str => Number(str));
    var annual_cash_flows_financing_cash_flows = this.inputsValue.annual_cash_flows_financing_cash_flows.map(str => Number(str));
    var annual_cash_flows_net_income = this.inputsValue.annual_cash_flows_net_income.map(str => Number(str));

    Highcharts.setOptions({
        lang: {
          numericSymbols: ['Th', ' M', 'B', 'T']
        },
        credits: {
            enabled: false
        }
    });

    Highcharts.chart('container5', {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Yearly Cash Flows'
        },
        xAxis: {
            categories: annual_cash_flows_years
        },
        yAxis: {
            title: {
                text: 'Value in USD'
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            column: {
               
            }
        },
        series: [{
            name: 'Operating Cash Flow',
            data: annual_cash_flows_operating_cash_flows
        }, {
            name: 'Investing Cash Flow',
            data: annual_cash_flows_investing_cash_flows
        }, {
            name: 'Financing Cash Flow',
            data: annual_cash_flows_financing_cash_flows
        }]
    });

    Highcharts.chart('container6', {

        title: {
            text: 'Net Income Vs Operating Cash Flow',

        },
    
        subtitle: {
            text: 'By Job Category. Source: <a href="https://irecusa.org/programs/solar-jobs-census/" target="_blank">IREC</a>.',
           
        },
    
        yAxis: {
            title: {
                text: 'Value in USD'
            }
        },
    
        xAxis: {
            categories: annual_cash_flows_years
        },
    
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
    

    
        series: [ {
            name: 'Net Income',
            data: annual_cash_flows_net_income
        }, {
            name: 'Operating Cash Flow',
            data: annual_cash_flows_operating_cash_flows
        }],
    
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    
    });
    
  }
}