import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

  static values = {
    inputs: Object
  }

  connect() {
   
    console.log(this.inputsValue);
  
  }
}