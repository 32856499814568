import { Controller } from "@hotwired/stimulus"
import Highcharts from 'highcharts';

export default class extends Controller {

    static values = {
        inputs: Object
    }

  connect() {

    var annual_income_statements_revenue = this.inputsValue.annual_income_statements_revenue.map(str => Number(str));
    var annual_income_statements_net_income = this.inputsValue.annual_income_statements_net_income.map(str => Number(str));
    var annual_income_statements_gross_profit = this.inputsValue.annual_income_statements_gross_profit.map(str => Number(str));
    var annual_income_statements_operating_income = this.inputsValue.annual_income_statements_operating_income.map(str => Number(str));

    Highcharts.setOptions({
        lang: {
          numericSymbols: ['Th', ' M', 'B', 'T']
        },
        credits: {
            enabled: false
        }
      });
      
    Highcharts.chart('container', {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Revenue Vs Earnings Trend',

        },
        xAxis: {
            categories: this.inputsValue.annual_income_statements_years.map(str => Number(str))
        },
        yAxis: {
            title: {
                text: `Value in ${this.inputsValue.annual_income_statements_reported_currency}`
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            column: {
                borderRadius: '0%'
            }
        },
        series: [{
            name: 'Revenue',
            data: annual_income_statements_revenue
        }, {
            name: 'Earnings',
            data: annual_income_statements_net_income
        }]
    });

    Highcharts.chart('container2', {

        title: {
            text: 'Income Distribution',
   
        },
        yAxis: {
            title: {
                text: `Value in ${this.inputsValue.annual_income_statements_reported_currency}`
            }
        },
    
        xAxis: {
            categories: this.inputsValue.annual_income_statements_years.map(str => Number(str))
        },
    
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
    
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
               
            }
        },
    
        series: [{
            name: 'Revenue',
            data: annual_income_statements_revenue
        }, {
            name: 'Gross Profit',
            data: annual_income_statements_gross_profit
        }, {
            name: 'Operating Income',
            data: annual_income_statements_operating_income
        }, {
            name: 'Net Income',
            data: annual_income_statements_net_income
        }],
    
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    
    });
  }
}