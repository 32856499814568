import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["search_form", "full_modal"];

  connect(){
    document.getElementById('global_search_trigger').addEventListener('focus', function () {
      // Toggle the "hidden" class on the "searchModal" div
      document.getElementById('searchModal').classList.toggle('hidden');
      document.getElementById('modal_search').focus();
    });

    // document.getElementById('modal_search').addEventListener('blur', function () {
    //   // Toggle the "hidden" class on the "searchModal" div
    //   document.getElementById('searchModal').classList.toggle('hidden');
    // });
    
  }

  closeGlobalSearchModal(){
    this.full_modalTarget.classList.toggle('hidden');
  }

  search_auto_submit(){
    this.search_formTarget.requestSubmit();
    // this.element.requestSubmit();
  }

 

}