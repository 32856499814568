import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    document.querySelectorAll('a[href^="scroll_"]').forEach(anchor => {
      anchor.addEventListener("click", function(e){
        e.preventDefault();
        console.log(this.getAttribute("href").replace('scroll_',''))
        let elmnt = document.getElementById(this.getAttribute("href"));
        if (this.getAttribute("href") === "scroll_stock_overview") {
          elmnt.scrollIntoView({ behavior: "smooth", block: "end"}); 
        } else {
          elmnt.scrollIntoView({ behavior: "smooth", block: "center" });    
        }

      })
    })
  }

}