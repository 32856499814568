import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    document.querySelectorAll('button[href^="collapse_"]').forEach(button => {
      button.addEventListener("click", function(e){
        e.preventDefault();
        var link = this.getAttribute("href")
        var elmnt = document.getElementsByClassName(link);
        var children = document.getElementsByClassName(`child_${link}`)
        if (elmnt[0].classList.contains("hidden")) {
          for (var i = 0; i < elmnt.length; i++) {
            elmnt[i].classList.remove("hidden");
            button.firstElementChild.classList.remove("-rotate-90");
          }
        } else {
          for (var i = 0; i < children.length; i++) {
            children[i].classList.add("hidden");
            if (children[i].firstElementChild.firstElementChild){
              children[i].firstElementChild.firstElementChild.firstElementChild.classList.add("-rotate-90");
            }
            button.firstElementChild.classList.add("-rotate-90");
          }
        }
      })
    })
  }

}