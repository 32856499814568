import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["mobile_menu"]

  connect(){
    console.log("toggle button pressed")
  }

  toggleMobileMenu(event){
    event.preventDefault();
    this.mobile_menuTarget.classList.toggle('hidden');
  }
}